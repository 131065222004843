.cke_dialog_ui_text{
    display: flex;
    align-items: center;
    padding-bottom:20px !important;
}
.cke_dialog_ui_labeled_label{
    font-size: 14px !important;
    margin-right:12px !important;
    color:#9CA3AF !important;
}
.cke_dialog_ui_labeled_content{
    flex:1;
    margin-left:10px;
}
.cke_dialog_ui_input_text{
    border-color: #e0dddd !important;
    border-radius:4px !important;
}
.cke_dialog_ui_vbox_child{
    padding:0px !important;
}
.cke_dialog_ui_html{
    padding-left: 56px !important;
    color:#6b7280 !important
}
.cke_dialog_footer{
    padding:5px 10px !important
}
.cke_dialog_contents_body{
    padding-top: 15px !important;
}
.cke_dialog_title{
    font-size: 16px !important;
}
.cke_dialog_contents_body{
    width: 550px !important;
    height: 117px !important;
}
.cke_dialog_contents{
    margin-top: 0 !important;
}
.cke_dialog_tabs{
    display: none !important;
}
.cke_dialog_ui_labeled_required{
    display: none !important;
}
.cke_dialog_ui_vbox_child .cke_dialog_ui_hbox_first .cke_dialog_ui_select{
    display: none !important;
    visibility: hidden !important;
}
.cke_dialog_ui_select .cke_dialog_ui_labeled_content{
    display: none !important;
}
.cke_resizer_ltr{
    display: none !important;
}
.link-url-should-go-class{
    font-size: 13px !important;
    padding-left: 56px !important;
    color:#6b7280 !important
}
.cke_dialog_image_ratiolock{
    display: none !important;
}
.cke_dialog_image_url .cke_dialog_ui_hbox_first{
    display: none !important;
    visibility: hidden !important;
}