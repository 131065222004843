.calendar table.month tr td{
  box-shadow: none !important;
}
.calendar table tr td{
  border:solid 1px #e5e7eb;
}
.calendar table.month tr td .day-content{
  padding:10px 6px;
}
.calendar table.month td.day .day-content{
  padding:10px 6px;
  border-radius: 0px !important;
  color:#111827;
}
.calendar table td, .calendar table th{
  width:54px;
  height:40px;
}
.calendar-header{
  display: none;
}
.calendar table.month td.day .day-content:hover{
  background-color: #f3f4f6;
}
.calendar .months-container .month-container{
  height:335px !important;
}
.calendar table.month th.day-header{
  border:solid 1px #d1d5db;
  color:#6b7280;
  font-weight: 400;
}
.calendar table.month th.month-title{
  color:#111827;
  font-weight:600;
}