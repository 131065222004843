@media only screen and (max-width: 1023px) and (min-width: 768px)  {
    .max-w-6{
      max-width:6rem;
    }
  }
  @media only screen and (max-width: 1280px) and (min-width: 1024px)  {
    .max-w-10{
      max-width:10rem;
    }
  }
  @media (min-width: 1280px) { 
    .max-w-14{
      max-width:14rem;
    }
  }

  .react-datepicker__aria-live{
    display: none !important;
  }
  .insights-page-height{
    height:calc(100vh - 36px);
  }
  .z-25{
    z-index: 25;
  }
  @media (max-width: 600px) { 
    .insights-page-height{
      height:auto;
    }
    .min-h300{
      min-height:300px;
    }
  }
  .bg-lime-600{
    background-color: #65a30d;
  }
  .bg-sky-500{
    background-color: #0477fb;
  }
  .bg-sky-500:hover{
    background-color: #0284c7;
  }
  .sidebar-active-tab-colour, .sidebar-hover-tab-colour:hover{
    background-color: #616161;
  }
  .wc-74{
    width: 74px;
  }
  .hc-74{
    height: 74px;
  }
  .bg-neutral-400 {
    background-color: #a3a3a3;
  }
  .bg-sky-700{
    background-color: #0369a1;
  }
  .border-neutral-500-custom {
    border: solid 1px #737373;
  }
  .border-1-c {
    border: solid 1px;
  }
  .max-wc-120 {
    max-width: 120px;
  }
  .max-wc-147 {
    max-width: 147px;
  }
  .topc-22 {
    top: 22px;
  }
  .max-wc-160 {
    max-width: 160px;
  }
  .max-wc-153 {
    max-width: 153px;
  }
  .second-step-error-message ul {
    margin-top: 8px;
    display: inline-grid;
    gap: 8px;
  }
  .w-c-26 {
    width: 104px;
  }
  .max-hc-365 {
    max-height: 365px;
  }
  .h-c-365 {
    height: 365px;
  }
  .max-w-28 {
    max-width: 7rem !important;
  }
  .max-w-20 {
    max-width: 5rem !important;
  }
  .cke_notifications_area {
    display: none !important;
  }
  .wc-102 {
    width: 102px;
  }
  .choose-file-button-class {
    background-color: #EFEFEF;
    border: 1px solid #EFEFEF !important;
    border-color: #767676 !important;
    border-radius: 2px !important;
  }
  .choose-file-button:hover {
    background-color: #E5E5E5;
  }
  .choose-file-button-div .title {
    display: none;
  }
  .choose-file-button-div:hover .title {
    display: block;
  }
  .max-w-28 {
    max-width: 112px;
  }
  @media (max-width: 600px) { 
    .mobile-filter-row{
      flex-direction: column !important;
    }
    .mobile-filter-col{
      width:100% !important
    }
  }
  .create-sub-instructor-grid .comment-input-container .mentions {
    margin: 10px 0 !important;
  }
  .class-container > div:nth-child(2) {
    width: 100% !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .class-container > div:nth-child(2) > div:nth-child(2) > span:nth-child(1) {
    display: none !important;
  }
  .r-select__option:active {
    background-color: #e5e7eb !important;
  }
  .r-select__control {
    cursor: pointer !important;
  }
  .select-disabled .r-select__control {
    cursor: not-allowed !important;
  }
  .r-select__input input {
    opacity: 1 !important;
  }
  .pb-c-340 {
    padding-bottom: 340px;
  }
  .sub-reuqest-requesting-instructor-filter > div {
    width: 100%;
  }
  .sub-reuqest-requesting-instructor-filter .dropdown {
    width: 100%;
    padding: 0;
    position: absolute;
    text-align: left;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
    border-radius: 16px;
    z-index: 20;
    margin-top: 8px;
  }
  .sub-reuqest-requesting-instructor-filter .item  {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 12px;
    color: #000;
  }
  .sub-reuqest-requesting-instructor-filter .item.selected-item {
    background-color: #616161;
    color: #FAFBFC;
  }
  .sub-reuqest-requesting-instructor-filter .item.selected-item:hover {
    background-color: #616161;
    color: #FAFBFC;
  }
  .sub-reuqest-requesting-instructor-filter .item:hover {
    background-color: #e5e7eb;
    color: #000;
  }
  .sub-reuqest-requesting-instructor-filter .item-hover {
    background-color: #e5e7eb;
    color: #000;
  }
  .custom-dots-loader {
    width: 25px !important;
    aspect-ratio: 5;
    background: radial-gradient(circle closest-side,#0003 90%,#0000) 0/calc(100%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1s steps(4) infinite;
  }
  @keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}
  @media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
      .safari-padding {
        margin-left: 32px;
        margin-right: 32px;
      }
    }
  }
  .max-wc-150 {
    max-width: 150px;
  }
  .footer-copyright-text {
    color: #808080;
    padding-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  .min-hc-150 {
    min-height: 150px;
  }
  .select-business-box-image {
    max-width: 160px;
    max-height: 70px;
  }
  .select-business-box-name {
    text-align: center;
    color: #fff;
    margin: 0;
    line-height: 22px;
  }
  .select-business-box {
    background-color: #ddd;
  }
  .forget-password-link-a {
    font-size: 11.81px !important;
    color: #337ab7 !important;
  }
  .business-image-login-logo {
    max-width: 130px;
    max-height: 45px;
    margin-left: 10px;
  }
  .custom-a-tag-blue-color {
    color: #337ab7 !important;
  }
  .custom-a-tag-blue-color:hover {
    text-decoration: underline;
    opacity: 0.7;
  }
  .tc-13 {
    font-size:13px !important;
  }
  .custom-w-h-200 {
    width: 200px;
    height: 200px;
  }
  .min-wc-330 {
    min-width: 330px;
  }
  .min-wc-110 {
    min-width: 110px;
  }
  .min-w-36 {
    min-width: 144px;
  }
  .min-wc-150 {
    min-width: 150px;
  }
  .tb-spacing{
    padding:0 0 0 7px;
  }
  .photos-attachment-container .str-chat__message-attachment-dynamic-size.str-chat__message-attachment--image, .links-attachment-container .str-chat__message-attachment-dynamic-size.str-chat__message-attachment--image {
    width: 140px !important;
  }
  .photos-attachment-container .str-chat__message-attachment--image .str-chat__message-attachment--img {
    height: 140px !important;
    object-fit: cover !important;
    width: 100% !important;
    border-radius: 6px !important;
  }
  .photos-attachment-container .str-chat__message-attachment--media.str-chat__message-attachment--video {
    width: 140px !important;
    height: 140px !important;
    border-radius: 6px !important;
  }
  .photos-attachment-container .str-chat__player-wrapper .react-player {
    height: 140px !important;
    border-radius: 6px !important;
  }
  .links-attachment-container .str-chat__message-attachment-card {
    border-radius: 6px !important;
    width: 140px !important;
    height: 140px !important;
    margin: 0 !important;
    border: none !important;
  }
  .links-attachment-container .str-chat__message-attachment-card--header {
    height: 80px !important;
  }
  .links-attachment-container .str-chat__message-attachment-card--content {
    height: 60px !important;
    background: #fff !important;
    padding: 6px 9px !important;
  }
  .links-attachment-container .str-chat__message-attachment-card--content .str-chat__message-attachment-card--text {
    display: none !important;
  }
  .links-attachment-container .str-chat__message-attachment--img {
    border-radius: 0 !important;
  }
  .links-attachment-container .str-chat__message-attachment-card--content .str-chat__message-attachment-card--title {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
  }
  .links-attachment-container .str-chat__message-attachment-card--content .str-chat__str-chat__message-attachment-card--flex {
    margin: 0 !important;
  }
  .links-attachment-container .str-chat__message-attachment-card--content .str-chat__message-attachment-card--url {
    text-decoration: underline !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .mind-id {
    width: 550px;
  }