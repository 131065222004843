.fc-scrollgrid-sync-table .fc-daygrid-day-top{
    flex-direction: row !important;
}
.fc-scrollgrid-sync-table .fc-daygrid-day-top .fc-daygrid-day-number{
    font-size: 14px !important;
    color:#171717 !important
}
.fc-scrollgrid-sync-inner .fc-col-header-cell-cushion{
    font-size:14px !important; 
    font-weight: 600 !important;
    color: #737373 !important;
}
.fc-daygrid-event-harness .fc-daygrid-event{
    white-space: break-spaces !important;
}
.fc-event .event-time{
    /* color:#525252; */
    font-size:11px !important;
    font-weight: 600 !important;
}
.fc-event{
    cursor: pointer !important;
}
.event-tile{
    font-size:11px !important;
    font-weight: 400;
    line-height: 16px;
}
.fc .fc-daygrid-day-frame{
    background: #fff !important;
}
.fc-daygrid-dot-event.fc-event-mirror, .fc-daygrid-dot-event:hover{
    background: transparent !important;
}
.link-more-text-color{
    color: #0566FA !important;
}
.fc-popover{
    max-width: 550px !important;
    border-radius:20px !important;
}
.fc-theme-standard .fc-popover-header{
    background: transparent !important;
}
.fc-popover-title{
    display: none !important;
}
.fc-popover-header{
    padding-top:10px !important;
    align-items: start !important;
}  
.fc-popover-close{
    margin-right:12px;
    padding-top:5px;
}
.fc .fc-popover{
    z-index: 10 !important;
}
.fc-popover-header .heading-div-content-1{
    width:100% !important;
    text-align: center !important;
}
.fc-popover-header .heading-div-content-1 label{
    display: block;
    text-transform: uppercase;
}
.fc-popover-header .heading-div-content-1 .popover-label1-class, .fc-popover-header .heading-div-content-1 .popover-label3-class{
    font-size:12px;
    color:#171717;
}
.fc-popover-header .heading-div-content-1 .popover-label2-class{
    font-size:16px;
    font-weight: 600;
    color:#171717;
}
.cal-blue{
  color:#2D7EFA;
}
.min-w-9{
  min-width: 36px;
}
.min-h-9{
  min-height: 36px;
}
.bg-emerald-600{
  background:#059669;
}
.bg-emerald-400{
  background:#34D399;
}
.bg-custom-gray{
	background: #EBEBEB;
}
.custom-blue-200{
  color:#2D7EFA;
}
.text-neutral-400{
  color:#a3a3a3;
}
.pending-text-color{
	color: #055CE1
}
.approved-text-color{
	color: #16A34A
}
.declined-text-color{
	color: #D32F2F
}
.fc-header-toolbar{
    display: none !important;
}
.status-mgr{
    background: #EFDFFB;
}
.status-open{
    background: #FEF9C3;
}
.status-pending{
    background: #E9F0FF;
}
.status-approved{
    background: #DCFCE7;
}
.status-no-show{
    background: #FFEDD5;
}
.status-canceleld{
    background: #FEE2E2;
}
.custom-red{
    color: #DC2626;
}
.assign-checkbox [type='checkbox'] {
    -webkit-appearance: none !important;
    border:solid 1px #ccc !important;
    border-radius:100px !important;
}         
.assign-checkbox [type='checkbox']:checked {
    -webkit-appearance: none !important;
    background-color: #171717 !important;
    background-image: url('/assets/check-dot.svg') !important;
}
.min-w-72{
    min-width: 326px;
}
.card-view-width{
    width: calc(100vw - 194px);
}
.border-open-status{
    border-left: 4px solid #EAB308;
}
.border-pending-status{
    border-left: 4px solid #2D7EFA;
}
.border-approved-status{
    border-left: 4px solid #16A34A;
}
.border-no-show-status{
    border-left: 4px solid #EA580C;
}
.border-canceleld-status{
    border-left: 4px solid #DC2626;
}
.border-mgr-status{
    border-left: 4px solid #7A56AA;
}
.no-show-text-color{
	color: #EA580C;
}
.cancelled-text-color{
	color: #DC2626;
}
.mgr-text-color{
	color: #65389E;
}
.fc-event:focus::after{
    background: transparent !important;
}
.fc-event:focus{
    box-shadow: none !important;
}
.fc .fc-popover{
    position: fixed !important;
    top: 100px !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
}
.fc-popover-body {
    max-height: 400px !important;
    overflow-y: auto !important;
}
.fc-day-past .fc-event .event-tile {
    opacity: 0.5 !important;
}
.fc-day-past .fc-event .event-time {
    opacity: 0.5 !important;
}
.fc-scrollgrid-sync-table .fc-day-today .fc-daygrid-day-number {
    width: 24px;
    height: 24px;
    background-color: #111827;
    border-radius: 100px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
}
.month-select-filter .react-datepicker-wrapper{
    width: auto !important;
}
.mClass{
	background: #000;
    color: #fff;
    border-radius: 100px;
    font-weight: bold;
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
}
.fc-scrollgrid-sync-inner{
    background-color: #f8f8f8;
}
.event-border{
    border: 0.5px solid;
}
.fc .fc-scrollgrid-section-sticky > * {
    z-index: auto !important;
}
.fc .fc-daygrid-event {
    z-index: auto !important;
}
.bg-gray-custom-50 {
    background-color: #f8f8f8;
}
.wc-14 {
    width: 14px;
}
.hc-14 {
    height: 14px;
}
.tc-11 {
    font-size:11px !important;
}
.min-w-8{
    min-width: 32px;
}
.min-h-8{
    min-height: 32px;
}
.tc-10 {
    font-size:10px !important;
}
.bg-custom-gray2{
	background: #f9f9f9 !important;
}
.border-green-custom {
    border: solid 1px #16A34A;
}
.text-green-c {
    color: #16A34A;
}
.min-w-8{
    min-width: 32px;
}
.min-h-8{
    min-height: 32px;
}
.fc-timeGridWeek-view .fc-timegrid-divider, .fc-timeGridWeek-view .fc-scrollgrid-sync-table {
    display: none !important;
}
.fc-timeGridDay-view .fc-timegrid-slot-minor, .fc-timeGridWeek-view .fc-timegrid-slot-minor {
    border: none !important;
}
.fc-timeGridWeek-view .fc-timegrid-slot-label-cushion, .fc-timeGridDay-view .fc-timegrid-slot-label-cushion {
    color: #737373 !important;
    font-weight: 600 !important;
    font-size: 12px !important;
}
.fc-timeGridWeek-view .fc-col-header col, .fc-timeGridWeek-view .fc-timegrid-slots col, .fc-timeGridWeek-view .fc-timegrid-cols col, .fc-timeGridDay-view .fc-timegrid-slots col, .fc-timeGridDay-view .fc-timegrid-cols col {
    width: 60px !important;
}
.fc-timeGridWeek-view .fc-timegrid-slot-label-frame {
    text-align: center !important;
    margin-top: -5px !important;
}
.fc-timeGridWeek-view .fc-timegrid-cols .fc-day {
    background-color: #ffffff !important;
}
.fc-timeGridDay-view .fc-timegrid-cols col, .fc-timeGridWeek-view .fc-timegrid-cols col {
    background-color: #f8f8f8;
}
.fc-timeGridWeek-view .fc-col-header-cell-cushion .header-day {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #737373 !important;
}
.fc-timeGridWeek-view .fc-col-header-cell-cushion .header-date {
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #737373 !important;
}
.fc-timeGridWeek-view .header-date {
    width: 26px;
    height: 26px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    margin-bottom: 5px;
}
.fc-timeGridWeek-view .fc-day-today .header-date {
    background-color: #111827;
    color: #fff !important;
}
.fc-timeGridWeek-view .fc-col-header-cell.fc-day {
    background-color: #f8f8f8;
}
.week-event-border{
    border-left: 1px solid;
}
.w-46 {
    width: 184px;
}
.fc-timeGridDay-view .fc-v-event, .fc-timeGridWeek-view .fc-v-event, .fc-more-popover .fc-popover-body .fc-v-event, .fc-multiMonthYear-view .fc-h-event, .fc-dayGridMonth-view .fc-h-event {
    background-color: #fff !important;
    border: none !important;
}
.fc-more-popover .fc-popover-body .fc-v-event {
    padding-top: 1px;
}
.week-selector-datepicker .react-datepicker-popper, .month-selector-filter .react-datepicker-popper {
    z-index: 999 !important;
}
.week-event-time-text {
    color: #737373 !important;
}
.text-neutral-500 {
    color: #737373;
}
.profile-notification-tooltip {
    display: none;
}
.profile-notification-container:hover .profile-notification-tooltip {
    display: block;
}
.filter-apply-border, .filter-dynamic-border {
    border: 2px solid #030712;
}
.need-border.filter-dynamic-border {
    opacity: 1;
}
.need-border {
    opacity: 0.7;
}
.calendar_view table:first-child tr:last-child td:first-child {
    border-bottom-left-radius: 16px; 
}
.calendar_view > div > div > div > table:first-child > tbody > tr:last-child td:first-child table:first-child tr:last-child td:first-child > .fc-daygrid-day-frame {
    border-bottom-left-radius: 16px; 
}
.calendar_view table:first-child tr:last-child td:last-child {
    border-bottom-right-radius: 16px; 
}
.calendar_view > div > div > div > table:first-child > tbody > tr:last-child td:first-child table:first-child tr:last-child td:last-child .fc-daygrid-day-frame {
    border-bottom-right-radius: 16px; 
}
.fc-scrollgrid {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.calendar_view > div > div > div {
    background-color: #fff;
}
.calendar_view > div > div > div > table:first-child, .calendar_view > div > div > div > table:first-child > thead > tr:first-child > th:first-child, .calendar_view > div > div > div > table:first-child > thead > tr:first-child > th:first-child > div > div > table:first-child > thead > tr:first-child > th:first-child > div {
    border-top-left-radius: 16px;
}
.calendar_view > div > div > div > table:first-child, .calendar_view > div > div > div > table:first-child > thead > tr:first-child > th:first-child, .calendar_view > div > div > div > table:first-child > thead > tr:first-child > th:first-child > div > div > table:first-child > thead > tr:first-child > th:last-child > div {
    border-top-right-radius: 16px;
}
.month-selector-filter .react-datepicker-year-header { 
    background-color: #fff !important;
    border-bottom: none !important;
}
.month-selector-filter .react-datepicker__month-text {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100%;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}
.month-selector-filter .react-datepicker__month-text--selected {
    background-color: #000 !important;
    color: #fff;
}
.month-selector-filter .react-datepicker {
    width: 200px !important;
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 8px !important;
}
.opacity-40 {
    opacity: 0.40;
}
.text-neutral-600 {
    color: #525252 !important;
}
.pagination-active-tab a {
    border: 1px solid #111827 !important;
    background-color: #111827 !important;
    color: #fff !important;
}
.list-view-pagination > ul > li:first-child > a {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}
.list-view-pagination > ul > li:last-child > a {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}
.fc-timeGridWeek-view > table:first-child > thead > tr:first-child > th:first-child > div {
    border-top-right-radius: 16px;
}
.bg-green-custom {
    background: #16A34A !important;
}
.max-w-64{
    max-width: 256px;
}
.shrink-0 {
    flex-shrink: 0;
}
.calendar-loader-screen{
    height: calc(100% - 40px);
    width: calc(100% - 64px);
    border-radius: 16px;
}
.month-change-button {
    padding: 2px;
    margin-right: 12px;
}
.month-change-left-button {
    transform: rotate(90deg);
}
.month-change-right-button {
    transform: rotate(-90deg);
}
.wc-130 {
    width: 130px !important;
}
.fc-timeGridDay-view .fc-scrollgrid-section-header {
    display: none !important;
}
.fc-timeGridDay-view > table:first-child > tbody > tr:first-child {
    display: none !important;
}
.fc-timeGridDay-view > table:first-child > tbody > tr .fc-timegrid-divider {
    display: none !important;
}
.fc-timeGridDay-view .fc-timegrid-col.fc-day-today {
    background-color: #fff !important;
}
.fc-timeGridDay-view > table:first-child > tbody > tr:last-child > td:first-child > div.fc-scroller-harness {
    border-top-left-radius: 16px !important;
}
.fc-timeGridDay-view > table:first-child > tbody > tr:last-child > td:first-child, .fc-timeGridDay-view .fc-timegrid-cols > table > tbody > tr:first-child > td:last-child {
    border-top-right-radius: 16px;
}
.fc-timeGridDay-view .fc-timegrid-col-events {
    width: 99.8% !important;
}
.fc-timeGridWeek-view .fc-timegrid-col-events {
    width: 98.4% !important;
}
.fc-multiMonthYear-view {
    border-radius: 16px !important;
}
.fc-multiMonthYear-view td, .fc-multiMonthYear-view th {
    border: none !important;
}
.fc-multiMonthYear-view .fc-scrollgrid-sync-inner {
    background-color: #fff;
}
.fc-multiMonthYear-view .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
    color: #525252;
    font-weight: 600;
    font-size: 12px;
}
.fc-multiMonthYear-view .fc-daygrid-day-number {
    text-align: center;
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 3px !important;
}
.fc-multiMonthYear-view .fc-multimonth-daygrid .fc-multimonth-daygrid-table tr, .fc-multiMonthYear-view .fc-multimonth-header-table {
    height: 40px;
}
.fc-multiMonthYear-view .fc-multimonth-title {
    color: #171717;
    font-weight: 600;
    font-size: 14px;
    padding: 16px 0px;
}
.year-event-dot {
    border-radius: 100%;
    box-sizing: content-box;
    height: 4px;
    width: 4px;
}
.fc-multiMonthYear-view .fc-day-today .fc-daygrid-day-number {
    width: 24px;
    height: 24px;
    background-color: #111827;
    border-radius: 100px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
}
.fc-multiMonthYear-view .fc-daygrid-day-top {
    justify-content: center;
}
.bg-mgr-event {
    background: #7A56AA !important;
}
.border-mgr-event {
    border: solid 1px #7A56AA;
}
.bg-pending-event {
    background: #2D7EFA !important;
}
.border-pending-event {
    border: 1px solid #2D7EFA;
}
.bg-open-event {
    background: #EAB308 !important;
}
.border-open-event {
    border: 1px solid #EAB308;
}
.bg-no-show-event {
    background: #EA580C !important;
}
.border-no-show-event {
    border: 1px solid #EA580C;
}
.bg-cancelled-event {
    background: #DC2626 !important;
}
.border-cancelled-event {
    border: 1px solid #DC2626;
}
.fc-multiMonthYear-view .fc-daygrid-dot-event {
    padding: 0px 0px !important;
}
.fc-multiMonthYear-view .fc-daygrid-event {
    margin-top: 0px !important;
}
.fc-multiMonthYear-view .fc-multimonth-daygrid-table .fc-daygrid-day-frame {
    cursor: pointer !important;
}
.max-wc-13 {
    max-width: 52px;
}
.list-loader-screen {
    width: calc(100% - 64px);
    border-radius: 16px;
}
.list-loader-screen-height {
    height: calc(100% - 60px);
}
.list-loader-screen-height-with-pagination {
    height: calc(100% - 108px);
}
.create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__header {
    background-color: #fff !important;
    border-bottom: none !important;
}
.create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
}
.create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__month {
    gap: 5px !important;
    display: flex !important;
    flex-direction: column !important;
}
.create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-names, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__week {
    display: flex !important;
    gap: 9px !important;
}
@media only screen and (min-width: 1556px) and (max-width: 1760px)  {
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-names, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__week {
        gap: 0px !important;
    }
    .time-picker-wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}
@media only screen and (min-width: 1431px) and (max-width: 1555px)  {
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, 
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
    }
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-names, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__week {
        gap: 0px !important;
    }
    .time-picker-wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}
@media only screen and (min-width: 1151px) and (max-width: 1430px)  {
    @media only screen and (min-width: 1351px)  {
        .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, 
        .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
            width: 27px !important;
            height: 27px !important;
            line-height: 27px !important;
        }
    }
    @media only screen and (min-width: 1280px) and (max-width: 1350px)  {
        .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, 
        .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
            width: 23px !important;
            height: 23px !important;
            line-height: 23px !important;
        }
    }
    @media only screen and (max-width: 1279px)  {
        .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, 
        .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
            width: 27px !important;
            height: 27px !important;
            line-height: 27px !important;
        }
    }
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-names, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__week {
        gap: 0px !important;
    }
    .time-picker-wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1150px)  {
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, 
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
        width: 23px !important;
        height: 23px !important;
        line-height: 23px !important;
    }
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-names, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__week {
        gap: 0px !important;
    }
    .time-picker-wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px)  {
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, 
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
    }
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-names, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__week {
        gap: 2px !important;
    }
    .time-picker-wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}
@media only screen and (min-width: 350px) and (max-width: 767px)  {
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, 
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
    }
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-names, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__week {
        gap: 5px !important;
    }
}
@media (max-width: 349px) {
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-name, 
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
    }
    .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day-names, .create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__week {
        gap: 0px !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1279px)  {
    .create-sub-request-page-custom-width {
        width: calc(100vw - 216px) !important;
    }
}
.create-sub-request-date-picker .react-datepicker__month-container .react-datepicker__day {
    border-radius: 100px !important;
}
.create-sub-request-date-picker .react-datepicker {
    border: none !important;
}
.create-sub-request-date-picker .react-datepicker__header .react-datepicker__current-month {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
}
.create-sub-request-date-picker .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
    color: #A3A3A3 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}
.opacity-c-100 {
    opacity: 1 !important;
}
.sub-request-create-comment-container .mentions__suggestions {
    max-height: 240px !important;
    overflow-y: auto !important;
}
.filter-row {
    display: flex;
    justify-content: space-between;
}
.create-comment-popup .mentions {
    margin: 10px 0 !important;
}
.fc-timeGridWeek-view .fc-v-event .fc-event-main, .fc-dayGridMonth-view .fc-h-event .fc-event-main {
    color: black !important;
}
.date-time-selector-width {
    width: calc(100% - 35px);
}